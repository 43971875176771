import SockJS from 'sockjs-client';
import drop from 'lodash/drop';
import { Stomp } from './stomp';
import { CURRENT_PAGE, INVESTMENTS_LEVELS } from "./store/actionNames";

let resolve;
let reject;
const onConnectPromise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
});

export default (app, options) => {

    const ws = new Ws(options);

    Object.defineProperty(app.config.globalProperties, '$ws', {
        get() {
            return ws;
        }
    });
};

class Ws {

    constructor(options) {
        this.options = options;

        const socket = new SockJS(options.context, undefined, {
            debug: false
        });
        this.nativeWs = Stomp.over(socket);

        this.nativeWs.debug = () => {};

        this.nativeWs.connect({}, (frame) => {
            resolve(frame);
        });
    }

    attachStore(store) {
        this.$store = store;
    }

    onConnect() {
        return onConnectPromise;
    }

    subscribe(topic, callback, id) {

        if (typeof callback !== 'function') {
            throw new Error('Provided callback is not a function');
        } else {
            this.nativeWs.subscribe(`/user${topic}`, (rawMessage) => {
                callback(JSON.parse(rawMessage.body), rawMessage.headers, rawMessage.command);
            }, {id: id});
        }
    }

    send(topic, headers, body) {
        this.nativeWs.send(topic, headers, JSON.stringify({
            ...body,
            investmentLevels: drop(this.$store.getValue(INVESTMENTS_LEVELS), 1),
            pageName: this.$store.getValue(CURRENT_PAGE + '.name')
        }));
    }
    unsubscribe(id) {
        this.nativeWs.unsubscribe(id);
    }
}
