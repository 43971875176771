<template lang="pug" src="./navigation.template.pug">
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

import ExpanderDirective from '../../commons/directives/expander.directive';
import {
    AUDITLOG_USER_SELECTED,
    AUDITLOG_DOCUMENT_SELECTED,
    INVESTMENTS_LEVELS,
} from '../../commons/store/actionNames';

export default {
    name: 'colmore-navigation',
    directives: {
        [ExpanderDirective.name]: ExpanderDirective,
    },
    data() {
        return {
            navigationData: [],
            storeElementsToClear: {
                'menu_link.audit_log': {
                    keys: [AUDITLOG_USER_SELECTED, AUDITLOG_DOCUMENT_SELECTED],
                    value: { name: null, id: null },
                },
            },
        };
    },

    mounted() {
        this.$ws.onConnect().then(() => {
            const value = this.$store.getValue(INVESTMENTS_LEVELS);
            if (value.length) {
                const siteType = value[value.length - 1].type || value[value.length - 1].pageName;

                this.$ws.send(
                    '/app/portalMenuItems',
                    {},
                    {
                        replyTo: '/c/portalMenuItems',
                        type: siteType,
                    },
                );
            }

            this.$ws.subscribe('/c/portalMenuItems', (body) => {
                this.navigationData = body.menuModelList || [];
                this.$emit('usernavlist', body.settingsMenuList || []);
            });
        });
    },

    methods: {
        linkClicked(ev, el) {
            if (el.items || el.url === '#') {
                ev.preventDefault();
                return false;
            }
            const keysToClear = this.storeElementsToClear[el.code];
            if (keysToClear) {
                keysToClear.keys.forEach((key) => {
                    this.$store.set(key, keysToClear.value, 'add');
                });
            }
        },
        blurNav() {
            this.navigationData.forEach((elem, i) => {
                if (elem.clicked) {
                    this.setVisibility(i, 'clicked', false);
                }
                if (elem.selected) {
                    this.setVisibility(i, 'selected', false);
                }
            });
        },
        setVisibility(i, key, val, secondKey) {
            if (this.$mq.desktop && i !== undefined) {
                let test = this.navigationData;
                if (typeof i === 'number') {
                    if (secondKey && i > 0) {
                        test.some((item) => {
                            item[secondKey] = item[secondKey] && false;
                            item[key] = false;
                        });
                    }
                    test[i][key] = val;
                } else if (typeof i === 'object' && i.length === 2) {
                    test[i[0]].items.some((item) => {
                        item[key] = item[key] && false;
                        if (!test[i[0]].items[i[1]].selected) {
                            item.selected = item.selected && false;
                        }
                    });
                    test[i[0]].items[i[1]][key] = val;
                }
                this.navigationData = cloneDeep(test);
            }
        },
        checkIfReverseMenu(index) {
            return index > this.navigationData.length - 3;
        },
        setCode(code) {
            return code.replace('.', '_');
        },
    },
};
</script>
