import set from 'lodash/set';
import slice from 'lodash/slice';
import get from 'lodash/get';

import {
    EXPOSURE_BOXES_SETTINGS,
    GROUPING_EXPANDED_LEVELS,
    INVESTMENTS_LEVELS,
    PHAROS_BENCHMARK_ANALYZER_PARAMS,
    PHAROS_CASHFLOW_PARAMS,
    PHAROS_PME_PARAMS,
    PREVIOUS_PAGES,
    STATE_FILTER_TREE,
    UI_TRANSLATIONS,
} from './actionNames';

export default {
    [INVESTMENTS_LEVELS](state, { type, payload }) {
        switch (type) {
            case 'add':
                state.push(payload);
                return state;
            case 'remove':
                return slice(state, 0, payload);
            default:
                return state;
        }
    },
    [PREVIOUS_PAGES](state, { type, payload }) {
        switch (type) {
            case 'add':
                state.push(payload);
                return state;
            case 'remove':
                return slice(state, 0, payload);
            case 'set':
                return [payload];
            default:
                return state;
        }
    },
    [PHAROS_PME_PARAMS](state, { type, payload }) {
        switch (type) {
            case 'add':
                return {
                    ...state,
                    ...payload,
                };
            default:
                return state;
        }
    },
    [PHAROS_CASHFLOW_PARAMS](state, { type, payload }) {
        switch (type) {
            case 'add':
                return {
                    ...state,
                    ...payload,
                };
            default:
                return state;
        }
    },
    [PHAROS_BENCHMARK_ANALYZER_PARAMS](state, { type, payload }) {
        switch (type) {
            case 'add':
                return {
                    ...state,
                    ...payload,
                };
            default:
                return state;
        }
    },
    [UI_TRANSLATIONS](state, { type, payload }) {
        switch (type) {
            case 'add':
                return { ...state, ...payload };
            default:
                return state;
        }
    },
    [STATE_FILTER_TREE](state, { type, payload }) {
        switch (type) {
            case 'setByPath':
                set(state, payload.path, payload.value);
                return state;
            default:
                return state;
        }
    },
    [GROUPING_EXPANDED_LEVELS](state, { type, payload }) {
        switch (type) {
            case 'level':
                state[payload.level] = payload.value;
                return state;
            default:
                return state;
        }
    },
    [EXPOSURE_BOXES_SETTINGS](state, { type, payload }) {
        const currentState = get(state, payload.level);
        switch (type) {
            case 'add':
                currentState.push(payload.value);
                set(state, payload.level, currentState);
                return state;
            case 'remove':
                currentState.splice(payload.index, 1);
                set(state, payload.level, currentState);
                return state;
            case 'edit':
                set(state, payload.level + '[' + payload.index + ']', payload.value);
                return state;
            case 'order':
                const res = currentState.splice(payload.index, 1);
                currentState.splice(payload.newIndex, 0, ...res);
                set(state, payload.level, currentState);
                return state;
            default:
                return state;
        }
    },
};
