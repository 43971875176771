import {
    AUDIENCES_CONFIG_USER_SESSION,
    AUDITLOG_DOCUMENT_SELECTED,
    AUDITLOG_USER_SELECTED,
    BENCHMARKS_CONFIGURATION_FUND_SELECTED,
    BENCHMARKS_CONFIGURATION_HOLDINGS_SELECTED,
    COMPANIES_SELECTED,
    DOCUMENT_REVIEW_EDIT,
    DOCUMENT_REVIEW_SELECTED,
    DOCUMENTS_SELECTED,
    EXPOSURE_BOXES_SETTINGS,
    FILTERS_SELECTED,
    FUND_DETAILS_SELECTED,
    FUND_SELECTED,
    GROUPING_CURRENT_EXPANDED_LEVEL,
    INVESTMENTS_LEVELS,
    MANAGED_FUNDS_FILTERS,
    MANAGED_FUNDS_MASTER_FILTERS,
    MASTER_ALL_SELECTED,
    MASTER_DATA_VIEW,
    MASTER_SELECTED,
    MASTER_TYPE,
    MONITORING_SELECTED,
    PERFORMANCE_ATTRIBUTION_FUND_SELECTORS_SELECTED,
    PERFORMANCE_ATTRIBUTION_HOLDINGS_SELECTORS_SELECTED,
    PHAROS_BENCHMARK_ANALYZER_SELECTED,
    PHAROS_CASHFLOW_PACING,
    PHAROS_CASHFLOW_SELECTED,
    PHAROS_PME_SELECTED,
    PORTFOLIO_ANALYSIS_SELECTED,
    PREVIOUS_PAGES,
    REDIRECTED_TO,
    UI_FILTER_TREE_DATA,
} from '../commons/store/actionNames';

export const APP_CONFIG = {
    ws: {
        context: '/colmoreWs',
    },
    mq: {
        phone: '(max-width: 767px)',
        tablet: '(max-width: 1279px)',
        desktop: '(min-width: 1280px)',
    },
    store: {
        storagePrefix: 'colmore',
        persistent: [
            AUDIENCES_CONFIG_USER_SESSION,
            AUDITLOG_DOCUMENT_SELECTED,
            AUDITLOG_USER_SELECTED,
            BENCHMARKS_CONFIGURATION_FUND_SELECTED,
            BENCHMARKS_CONFIGURATION_HOLDINGS_SELECTED,
            COMPANIES_SELECTED,
            DOCUMENTS_SELECTED,
            DOCUMENT_REVIEW_EDIT,
            DOCUMENT_REVIEW_SELECTED,
            EXPOSURE_BOXES_SETTINGS,
            FILTERS_SELECTED,
            FUND_DETAILS_SELECTED,
            FUND_SELECTED,
            GROUPING_CURRENT_EXPANDED_LEVEL,
            INVESTMENTS_LEVELS,
            MANAGED_FUNDS_FILTERS,
            MANAGED_FUNDS_MASTER_FILTERS,
            MASTER_ALL_SELECTED,
            MASTER_DATA_VIEW,
            MASTER_SELECTED,
            MASTER_TYPE,
            MONITORING_SELECTED,
            PERFORMANCE_ATTRIBUTION_FUND_SELECTORS_SELECTED,
            PERFORMANCE_ATTRIBUTION_HOLDINGS_SELECTORS_SELECTED,
            PHAROS_BENCHMARK_ANALYZER_SELECTED,
            PHAROS_CASHFLOW_PACING,
            PHAROS_CASHFLOW_SELECTED,
            PHAROS_PME_SELECTED,
            PORTFOLIO_ANALYSIS_SELECTED,
            PREVIOUS_PAGES,
            REDIRECTED_TO,
            UI_FILTER_TREE_DATA,
        ],
    },
    pages: {
        ACTIVITY_SUMMARY: {
            labelCode: 'page_name.activity_summary',
            name: 'ACTIVITY_SUMMARY',
            link: '/activitySummary.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        AUDIENCE: {
            labelCode: 'page_name.audience',
            name: 'AUDIENCE',
            link: '/audience.html',
        },
        AUDIT_LOG: {
            labelCode: 'page_name.audit_log',
            name: 'AUDIT_LOG',
            link: '/auditLog.html',
        },
        BANK_DETAILS: {
            labelCode: 'page_name.bank_details',
            name: 'BANK_DETAILS',
            link: '/bankDetails.html',
        },
        BENCHMARK_ANALYZER: {
            labelCode: 'page_name.benchmark_analyzer',
            name: 'BENCHMARK_ANALYZER',
            link: '/benchmarkAnalyzer.html',
            filterKey: 'pharosBenchmarkAnalyzerFilters',
            masterFilterKey: 'masterFilters',
        },
        CASHFLOWS: {
            labelCode: 'page_name.investment_cashflows',
            name: 'CASHFLOWS',
            link: '/cashflows.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        CASHFLOW_SIMULATOR: {
            labelCode: 'page_name.cashflow_simulator',
            name: 'CASHFLOW_SIMULATOR',
            link: '/cashflowSimulator.html',
            filterKey: 'pharosCashflowFilters',
            masterFilterKey: 'masterFilters',
        },
        COMPANY_EXPOSURE: {
            labelCode: 'page_name.investment_exposure',
            name: 'COMPANY_EXPOSURE',
            link: '/pcoExposure.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        COMPANY_SEARCH: {
            labelCode: 'page_name.company_search',
            name: 'COMPANY_SEARCH',
            link: '/companySearch.html',
            masterFilterKey: 'masterFilters',
        },
        DAILY_VALUATION: {
            labelCode: 'page_name.daily_valuation',
            name: 'DAILY_VALUATION',
            link: '/dailyValuation.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        DASHBOARDS: {
            label: 'Dashboards',
            name: 'DASHBOARDS',
            link: '/dashboards.html',
        },
        DIVERSIFICATION: {
            labelCode: 'page_name.portfolio_diversification',
            name: 'DIVERSIFICATION',
            link: '/diversification.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        DOCUMENTS: {
            labelCode: 'page_name.documents',
            name: 'DOCUMENTS',
            link: '/documents.html',
            filterKey: 'documentsFilters',
        },
        DOCUMENT_DETAIL: {
            labelCode: 'page_name.document_detail',
            name: 'DOCUMENT_DETAIL',
            link: '/documentDetail.html',
            filterKey: 'documentDetailFilters',
        },
        DOCUMENT_REVIEW: {
            labelCode: 'page_name.document_review',
            name: 'DOCUMENT_REVIEW',
            link: '/documentReview.html',
            filterKey: 'documentReviewFilters',
        },
        DOCUMENT_UPLOAD: {
            labelCode: 'page_name.document_upload',
            name: 'DOCUMENT_UPLOAD',
            link: '/documentUpload.html',
        },
        FUND_CASHFLOW: {
            labelCode: 'page_name.fund_cashflows',
            name: 'FUND_CASHFLOW',
            link: '/fundCashflow.html',
            filterKey: 'fundsFilters',
            masterFilterKey: 'managedFundsMasterFilters',
        },
        FUND_DETAIL: {
            labelCode: 'page_name.fund_detail',
            name: 'FUND_DETAIL',
            link: '/fundDetail.html',
            filterKey: 'fundDetailsFilters',
            masterFilterKey: 'masterFilters',
        },
        INVESTMENTS: {
            labelCode: 'page_name.investments',
            name: 'INVESTMENTS',
            link: '/investments.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        INVESTMENT_DIVERSIFICATION: {
            labelCode: 'page_name.investment_diversification',
            name: 'INVESTMENT_DIVERSIFICATION',
            link: '/pcoDiversification.html',
            filterKey: 'companiesFilters',
            masterFilterKey: 'masterFilters',
        },
        INVESTMENTS_LIST: {
            labelCode: 'page_name.investment_list',
            name: 'INVESTMENTS_LIST',
            link: '/investmentList.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        INVESTMENTS_TRACE_FILE: {
            labelCode: 'page_name.investments_trace_file',
            name: 'INVESTMENTS_TRACE_FILE',
            link: '/investmentsTraceFile.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        IRR_ANALYSIS: {
            labelCode: 'page_name.irr_analysis',
            name: 'IRR_ANALYSIS',
            link: '/irrAnalysis.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        JCURVE: {
            labelCode: 'page_name.jcurve',
            name: 'JCURVE',
            link: '/jCurve.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        MANAGED_FUNDS: {
            labelCode: 'page_name.managed_funds',
            name: 'MANAGED_FUNDS',
            link: '/managedFunds.html',
            filterKey: 'fundsFilters',
            masterFilterKey: 'managedFundsMasterFilters',
        },
        MANAGED_FUNDS_TRACE_FILE: {
            labelCode: 'page_name.managed_funds_trace_file',
            name: 'MANAGED_FUNDS_TRACE_FILE',
            link: '/managedFundsTraceFile.html',
            filterKey: 'fundsFilters',
            masterFilterKey: 'managedFundsMasterFilters',
        },
        MONITORING: {
            labelCode: 'page_name.monitoring',
            name: 'MONITORING',
            link: '/monitoring.html',
            filterKey: 'monitoringFilters',
            masterFilterKey: 'masterFilters',
        },
        NOTICES: {
            labelCode: 'page_name.notices',
            name: 'NOTICES',
            link: '/notices.html',
        },
        NOTIFICATIONS: {
            labelCode: 'page_name.notifications',
            name: 'NOTIFICATIONS',
            link: '/notifications.html',
        },
        OVERVIEW: {
            labelCode: 'page_name.investment_overview',
            name: 'OVERVIEW',
            link: '/overview.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        PERFORMANCE_AND_BENCHMARKING: {
            labelCode: 'page_name.perfbenchmarking',
            name: 'PERFORMANCE_AND_BENCHMARKING',
            link: '/perfbenchmarking.html',
            filterKey: 'portfolioAnalysisFilters',
            masterFilterKey: 'masterFilters',
        },
        PERSONAL_DETAILS: {
            labelCode: 'page_name.personal_details',
            name: 'PERSONAL_DETAILS',
            link: '/personalDetails.html',
        },
        PME: {
            labelCode: 'page_name.pme',
            name: 'PHAROS_PME',
            link: '/pme.html',
            filterKey: 'pharosPmeFilters',
            masterFilterKey: 'masterFilters',
        },
        PORTFOLIO_COMPOSITION_OVER_TIME: {
            labelCode: 'page_name.portfolio_composition_over_time',
            name: 'PORTFOLIO_COMPOSITION_OVER_TIME',
            link: '/portcomposition.html',
            filterKey: 'portfolioAnalysisFilters',
            masterFilterKey: 'masterFilters',
        },
        PORTFOLIO_EXPOSURE: {
            labelCode: 'page_name.exposure',
            name: 'PORTFOLIO_EXPOSURE',
            link: '/exposure.html',
            filterKey: 'portfolioAnalysisFilters',
            masterFilterKey: 'masterFilters',
        },
        REPORTS: {
            labelCode: 'page_name.reports',
            name: 'REPORTS',
            link: '/reports.html',
        },
        TEMPLATES: {
            labelCode: 'page_name.templates',
            name: 'TEMPLATES',
            link: '/templates.html',
        },
        TOP_COMPANIES: {
            labelCode: 'page_name.top_companies',
            name: 'TOP_COMPANIES',
            link: '/topCompanies.html',
            filterKey: 'companiesFilters',
            masterFilterKey: 'masterFilters',
        },
        TRADING_VALUES: {
            labelCode: 'page_name.trading_values',
            name: 'TRADING_VALUES',
            link: '/tradingValues.html',
            filterKey: 'filters',
            masterFilterKey: 'masterFilters',
        },
        USER_DOCUMENT_DETAIL: {
            labelCode: 'page_name.user_document_detail',
            name: 'USER_DOCUMENT_DETAIL',
            link: '/userDocumentDetail.html',
            filterKey: 'userDocumentDetailFilters',
        },
        USER_SETTINGS: {
            labelCode: 'page_name.user_settings',
            name: 'USER_SETTINGS',
            link: '/userSettings.html',
        },
    },
};
