export default {
    ui: {
        isMasterFiltersExpanded: false,
        isLoaderExpanded: false,
        isFiltersLoading: false,
        sidebarFilterType: '',
        translations: {},
        filterTree: {
            mode: '',
            params: {},
            data: {
                name: '',
                id: undefined,
            },
        },
    },
    currentPage: {
        name: '',
    },
    exposureBoxesSettings: {},
    previousPages: [],
    redirectedTo: '',
    investmentsLevels: [
        {
            nameCode: 'page_header.portfolio',
            type: '',
            link: '/investments.html',
            pageNameCode: 'page_name.investments',
            code: '',
        },
    ],
    groupingCategories: {
        categories: [],
        categoriesExpanded: [false, false, false, false],
        categoryClicked: null,
    },
    filterState: {
        filterTree: [],
    },
    masterFilters: {
        meta: [],
        selected: [],
        filtersText: {},
        filterType: null,
        dataView: null,
        allSelected: {},
    },
    managedFundsMasterFilters: {
        meta: [],
        selected: [],
        filtersText: {},
        filterType: null,
        dataView: null,
        allSelected: {},
    },
    filters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: null,
        },
        filtersText: {},
    },
    auditLogFilters: {
        documentSelected: {
            name: null,
            id: null,
        },
        userSelected: {
            name: null,
            email: null,
            id: null,
            link: null,
        },
    },
    companiesFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    documentsFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
            categories: null,
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
            categories: [],
        },
        filtersText: {},
    },
    documentDetailFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    documentReviewFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            filters: [],
            subFilters: [],
        },
        filtersText: {},
        editDocumentId: null,
    },
    fundsFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    fundDetailsFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    managedFundsFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    monitoringFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    pharosBenchmarkAnalyzerFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
        params: {},
    },
    pharosCashflowFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
        params: {},
        pacing: {},
    },
    pharosPmeFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
        params: {},
    },
    portfolioAnalysisFilters: {
        meta: {
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
    },
    performanceAttributionSelectors: {
        fund: {
            selected: [],
        },
        holdings: {
            selected: [],
        },
    },
    userDocumentDetailFilters: {
        meta: {
            filters: null,
            subFilters: null,
            sections: {},
        },
        selected: {
            masterFilters: [],
            filters: [],
            subFilters: [],
        },
        filtersText: {},
    },
    benchmarksConfiguration: {
        fund: {
            selected: null,
        },
        holdings: {
            selected: null,
        },
    },
    audiencesConfig: {
        userSession: null
    },
};
