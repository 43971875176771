import Store from './store';

export default (app, options) => {
    const store = new Store(options);

    Object.defineProperty(app.config.globalProperties, '$store', {
        get() {
            return store;
        }
    });
};
