// @ts-check
import { createApp } from 'vue';
import axios from 'axios';
import findLastIndex from 'lodash/findLastIndex';

// @ts-ignore
import logoImage from 'assetsPath/img/helios_logo.png';

import MQ from '../../commons/vue-match-media';
import { Vuebar } from '../../commons/libs/vuebar';
import 'simplebar';
import '../../styles/main.scss';

import { APP_CONFIG } from '../../config';
import {
    INVESTMENTS_LEVELS,
    PREVIOUS_PAGES,
    PHAROS_PME_SELECTED,
    UI_TRANSLATIONS,
    CURRENT_PAGE,
} from '../../commons/store/actionNames';

import store from '../../commons/store';
import SocketService from '../../commons/websocketService';

import Navigation from '../../globalComponents/navigation/navigation.component.vue';
import ExpanderDirective from '../../commons/directives/expander.directive';
import Modal from '../../components/modal/modal.component.vue';

import { LicenseManager } from 'ag-grid-enterprise';

const axiosInstance = axios.create({});

const MESSAGE_PREFIX = [
    'page_name',
    'page_header.portfolio',
    'page_link.edit_financials',
    'portfolio_diversification_point_in_time',
    'pco_diversification_point_in_time',
    'password.change',
    'lookthrough.tree.investments_label',
];

const AG_GRID_LICENSE_REST_URL = '/adm/agGridLicense';

export function createDefaultPage(mixin) {
    const app = createApp({
        compatConfig: {
            RENDER_FUNCTION: false,
        },

        directives: {
            [ExpanderDirective.name]: ExpanderDirective,
        },
        components: {
            [Navigation.name]: Navigation,
            [Modal.name]: Modal,
        },
        mixins: [mixin],
        mq: APP_CONFIG.mq,
        data() {
            return {
                isUserProfileExpanded: false,
                computeProgress: false,
                menuExpanded: false,
                displayPresets: false,
                hover: false,
                checkNumberOfPresets: false,
                showEdit: false,
                showFilterSidebarIcon: false,
                showSubfiltersSidebarIcon: false,
                userNavList: [],
                statement: {},
                isSidebarFiltersLock: false,
                mainCssStyle: {},
                logoImage,
                pageTitle: '',
                unread: 0,
            };
        },
        watch: {
            menuExpanded(isExpanded) {
                document.body.style.overflowY = isExpanded ? 'hidden' : '';
            },
            isUserProfileExpanded(isExpanded) {
                document.body.style.overflowY = isExpanded ? 'hidden' : '';
            },
        },
        created() {
            this.$http.get(AG_GRID_LICENSE_REST_URL).then((body) => {
                LicenseManager.setLicenseKey(body.data);
            });

            window.onhashchange = this.updateHistory();
        },

        mounted() {
            if (this.$ws) {
                this.$ws.attachStore(this.$store);
                this.$ws.onConnect().then(() => {
                    this.$ws.subscribe('/c/notification-count', (body) => {
                        this.unread = body.count;
                    });
                    this.$ws.subscribe('/c/impersonationStatus', (status) => {
                        app.config.globalProperties.$impersonated = status.impersonated;
                    });
                    this.$ws.send(
                        '/app/notification-count',
                        {},
                        {
                            replyTo: '/c/notification-count',
                        },
                    );
                    this.$ws.send(
                        '/app/impersonationStatus',
                        {},
                        {
                            replyTo: '/c/impersonationStatus',
                        },
                    );

                    this.$ws.subscribe('/c/messageAll', (messages) => {
                        this.$store.set(UI_TRANSLATIONS, messages, 'add');
                    });
                    MESSAGE_PREFIX.forEach((prefix) => {
                        this.$ws.send(
                            '/app/message/all/code-value',
                            {},
                            {
                                replyTo: '/c/messageAll',
                                codePrefix: prefix,
                            },
                        );
                    });
                });
            }

            // manually add some properties to the root element
            let mqClass = 'is-desktop-view';
            if (this.$mq.phone && !this.$mq.tablet) {
                mqClass = 'is-mobile-view';
            } else if (!this.$mq.phone && this.$mq.tablet) {
                mqClass = 'is-tablet-view';
            }

            this.$el.classList?.add(mqClass);

            if (this.isSidebarFiltersLock) {
                this.$el.classList?.add('sidebar-locked');
            }

            this.$el.addEventListener('mousedown', this.unselectNavigation);
        },

        unmounted() {
            this.$el.removeEventListener('mousedown', this.unselectNavigation);

        },

        methods: {
            closeSideNav() {
                if (this.menuExpanded) {
                    this.menuExpanded = false;
                    this.collapseAllNavChildren(this.$refs.navigation.$refs.navListLvl0);
                    this.collapseAllNavChildren(this.$refs.navigation.$refs.navListLvl1);
                } else if (this.isUserProfileExpanded) {
                    this.isUserProfileExpanded = false;
                }
            },
            setUserNavList(value) {
                this.userNavList = value;
            },
            setUserProfileExpanded() {
                if (this.$mq.phone || this.$mq.tablet) {
                    this.isUserProfileExpanded = true;
                    this.menuExpanded = false;
                }
            },
            setMenuExpanded(val) {
                this.menuExpanded = val;
            },
            collapseAllNavChildren(item) {
                const listItemLvl = item;
                for (const listItem in listItemLvl) {
                    const item = listItemLvl[listItem];
                    if (item.classList.contains('is-expanded')) {
                        item.classList.remove('is-expanded');
                    }
                }
            },
            clearSession() {
                this.$store.clearStorage();
            },
            setStatement(obj) {
                this.statement = obj;
            },
            setPreviousPage(page, level, action) {
                this.$store.set(
                    PREVIOUS_PAGES,
                    {
                        name: page.name,
                        level: level,
                        link: page.link,
                        labelCode: page.labelCode,
                    },
                    action,
                );
            },
            updateHistory() {
                if (this.$store) {
                    const page = this.$store.getValue(CURRENT_PAGE);
                    const prevPages = this.$store.getValue(PREVIOUS_PAGES);
                    const levels = this.$store.getValue(INVESTMENTS_LEVELS);
                    const allLevels = levels.length;

                    if (prevPages.length > 1 && allLevels <= prevPages.slice(-1)[0].level) {
                        const currentIndex = findLastIndex(
                            prevPages.slice(0, -1),
                            (el) => el.name === page.name && el.level <= allLevels,
                        );
                        if (currentIndex > -1) {
                            this.$store.set(PREVIOUS_PAGES, currentIndex + 1, 'remove');
                            if (allLevels > prevPages[currentIndex].level) {
                                this.$store.set(INVESTMENTS_LEVELS, allLevels - 1, 'remove');
                            }
                        } else {
                            this.setPreviousPage(page, allLevels, 'add');
                        }
                    } else {
                        this.setPreviousPage(page, allLevels, allLevels > 1 ? 'add' : 'set');
                        this.$store.set(PHAROS_PME_SELECTED, {
                            masterFilters: [],
                            filters: [],
                            subFilters: [],
                        });
                    }
                }
            },
            unselectNavigation() {
                this.$refs.navigation?.blurNav();
            },
        },
    });

    app.config.compilerOptions.whitespace = 'preserve';

    app.use(MQ);
    app.use(Vuebar);

    app.use(store);
    if (window.location.pathname !== '/login') {
        app.use(SocketService, APP_CONFIG.ws);
    }

    Object.defineProperty(app.config.globalProperties, '$http', {
        get() {
            return axiosInstance;
        },
    });

    return app;
}
