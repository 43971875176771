<template lang="pug" src="./modal.template.pug">
</template>

<script>
export default {
    name: 'hy-modal',
    props: {
        closeButton: {
            default: true,
            type: Boolean,
        },
        showModalText: { type: String },
        modalClass: {
            default: '',
            type: String,
        },
        buttonClass: {
            default: 'o-btn--link',
            type: String,
        },
        modalVisible: {
            default: false,
            type: Boolean,
        },
        closeOnClick: {
            default: true,
            type: Boolean,
        },
        showModalButton: {
            default: true,
            type: Boolean,
        },
        modalHeight: {
            default: '60vh',
            type: String,
        },
        buttonDisabled: {
            default: false,
            type: Boolean,
        },
        alignButtonClass: {
            default: '',
            type: String,
        },
        alwaysRender: {
            default: false,
            type: Boolean,
        },
        dataTestId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        hasSlotData() {
            return this.$slots.default();
        },
    },
    watch: {
        modalVisible(val) {
            if (!val) {
                this.showModal = false;
            } else {
                this.openModal();
            }
        },
    },
    methods: {
        openModal() {
            this.showModal = true;
            this.$emit('opened', true);
        },
        closeModal() {
            this.showModal = false;
            this.$emit('clear', true);
        },
        closeModalOnClick() {
            if (this.closeOnClick) {
                this.closeModal();
            }
        },
    },
};
</script>

<style></style>
