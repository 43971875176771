/* eslint no-unused-vars:0 */

import { reactive } from 'vue';

const MQ = 'VUE-MATCH-MEDIA-MQ'
const MQMAP = 'VUE-MATCH-MEDIA-MQUERIES'

export default (app, options) => {
    Object.defineProperty(app.config.globalProperties, '$mq', {
        get() {
            return this[MQ]
        }
    })

    app.mixin({
        beforeCreate() {
            const isIsolated = this.$options.mq && this.$options.mq.config && this.$options.mq.config.isolated
            const isRoot = this === this.$root
            const inherited = this.$parent && this.$parent[MQMAP]
            const inheritedKeys = isIsolated || isRoot || !inherited ? [] : Object.keys(inherited)

            if (this.$options.mq) {
                this[MQMAP] = {}

                const mergedKeys = new Set(inheritedKeys.concat(
                    Object.keys(this.$options.mq)
                        .filter(k => k !== 'config')
                ))

                const observed = Array.from(mergedKeys)
                    .reduce((obs, k) => {
                        const ownQuery = this.$options.mq[k]
                        const mql = ownQuery ? window.matchMedia(ownQuery) : inherited[k]
                        mql.addListener(e => {
                            obs[k] = e.matches
                        })

                        obs[k] = mql.matches
                        this[MQMAP][k] = mql
                        return obs
                    }, {})

                Object.defineProperty(observed, 'all', {
                    enumerable: true,
                    configurable: true,
                    get() {
                        return Object.keys(this)
                            .filter(k => k !== 'all')
                            .filter(k => this[k])
                    }
                })

                this[MQ] = reactive(observed);
            } else if (inherited) {
                this[MQMAP] = inherited
                this[MQ] = reactive(this.$parent[MQ]);
            }
        }
    })
}
