export const META_FILTERS = 'meta.filters';
export const META_SUB_FILTERS = 'meta.subFilters';
export const META_SECTIONS = 'meta.sections';
export const META_CATEGORIES = 'meta.categories';
export const SELECTED = 'selected';
export const SELECTED_FILTERS = 'selected.filters';
export const SELECTED_SUB_FILTERS = 'selected.subFilters';
export const SELECTED_PREDEFINEDSUB = 'selected.predefinedSubFilter';
export const SELECTED_CATEGORIES = 'selected.categories';
export const TEXT_FILTERS = 'filtersText';

export const IS_SIDEBAR_FILTERS_EXPANDED = 'ui.isSidebarFiltersExpanded';
export const IS_MASTER_FILTERS_EXPANDED = 'ui.isMasterFiltersExpanded';
export const IS_SIDEBAR_FILTERS_LOCK = 'ui.isSidebarFiltersLock';
export const UI_SIDEBAR_FILTERS_TYPE = 'ui.sidebarFilterType';
export const IS_FILTERS_LOADING = 'ui.isFiltersLoading';
export const UI_TRANSLATIONS = 'ui.translations';
export const UI_FILTER_TREE_MODE = 'ui.filterTree.mode';
export const UI_FILTER_TREE_PARAMS = 'ui.filterTree.params';
export const UI_FILTER_TREE_DATA = 'ui.filterTree.data';

export const INVESTMENTS_LEVELS = 'investmentsLevels';
export const CURRENT_PAGE = 'currentPage';
export const PREVIOUS_PAGES = 'previousPages';
export const REDIRECTED_TO = 'redirectedTo';

export const STATE_FILTER_TREE = 'filterState.filterTree';

export const IRR_ANALYSIS_LEVELS = 'IRRAnalysisLevels';

export const FILTERS = 'filters';
export const FILTERS_SELECTED = FILTERS + '.' + SELECTED;
export const FILTERS_META_SUB_FILTERS = FILTERS + '.' + META_SUB_FILTERS;

export const DOCUMENTS = 'documentsFilters';
export const DOCUMENTS_SELECTED = DOCUMENTS + '.selected';
export const DOCUMENTS_META_SUB_FILTERS = DOCUMENTS + '.' + META_SUB_FILTERS;

export const DOCUMENT_DETAIL = 'documentDetailFilters';
export const DOCUMENT_DETAIL_SELECTED = DOCUMENT_DETAIL + '.selected';
export const DOCUMENTS_DETAIL_META_FILTERS = DOCUMENT_DETAIL + '.meta.filters';

export const DOCUMENT_REVIEW = 'documentReviewFilters';
export const DOCUMENT_REVIEW_SELECTED = DOCUMENT_REVIEW + '.selected';
export const DOCUMENT_REVIEW_META_SUB_FILTERS = DOCUMENT_REVIEW + '.' + META_SUB_FILTERS;
export const DOCUMENT_REVIEW_EDIT = DOCUMENT_REVIEW + '.editDocumentId';

export const USER_DOCUMENT_DETAIL = 'userDocumentDetailFilters';
export const USER_DOCUMENT_DETAIL_SELECTED = USER_DOCUMENT_DETAIL + '.selected';
export const USER_DOCUMENT_DETAIL_META_FILTERS = USER_DOCUMENT_DETAIL + '.meta.filters';

export const MONITORING = 'monitoringFilters';
export const MONITORING_SELECTED = 'monitoringFilters.selected';
export const MONITORING_META_FILTERS = 'monitoringFilters.meta.filters';
export const MONITORING_META_SUB_FILTERS = MONITORING + '.' + META_SUB_FILTERS;

export const FUND = 'fundsFilters';
export const FUND_SELECTED = FUND + '.selected';
export const FUND_META_SUB_FILTERS = FUND + '.' + META_SUB_FILTERS;

export const FUND_DETAILS = 'fundDetailsFilters';
export const FUND_DETAILS_SELECTED = FUND_DETAILS + '.selected';
export const FUND_DETAILS_META_SUB_FILTERS = FUND_DETAILS + '.' + META_SUB_FILTERS;

export const COMPANIES_FILTERS = 'companiesFilters';
export const COMPANIES_SELECTED = COMPANIES_FILTERS + '.selected';
export const COMPANIES_META_SUB_FILTERS = COMPANIES_FILTERS + '.' + META_SUB_FILTERS;

export const PHAROS_PME_SELECTED = 'pharosPmeFilters.selected';
export const PHAROS_PME_PARAMS = 'pharosPmeFilters.params';
export const PHAROS_BENCHMARK_ANALYZER_SELECTED = 'pharosBenchmarkAnalyzerFilters.selected';
export const PHAROS_BENCHMARK_ANALYZER_PARAMS = 'pharosBenchmarkAnalyzerFilters.params';
export const PHAROS_CASHFLOW_SELECTED = 'pharosCashflowFilters.selected';
export const PHAROS_CASHFLOW_PARAMS = 'pharosCashflowFilters.params';
export const PHAROS_CASHFLOW_PACING = 'pharosCashflowFilters.pacing';

export const DOCUMENTS_META_FILTERS = 'documentsFilters.meta.filters';

export const MASTER_META_FILTERS = 'masterFilters.meta';
export const MASTER_SELECTED = 'masterFilters.selected';
export const MASTER_TEXT_FILTERS = 'masterFilters.filtersText';
export const MASTER_TYPE = 'masterFilters.filterType';
export const MASTER_DATA_VIEW = 'masterFilters.dataView';
export const MASTER_ALL_SELECTED = 'masterFilters.allSelected';

export const AUDITLOG_DOCUMENT_SELECTED = 'auditLogFilters.documentSelected';
export const AUDITLOG_USER_SELECTED = 'auditLogFilters.userSelected';

export const GROUPING_CURRENT_EXPANDED_LEVEL = 'groupingCategories.categoryClicked';
export const GROUPING_EXPANDED_LEVELS = 'groupingCategories.categoriesExpanded';

export const MANAGED_FUNDS_FILTERS = 'managedFundsFilters.selected';
export const MANAGED_FUNDS_MASTER_FILTERS = 'managedFundsMasterFilters.selected';

export const PORTFOLIO_ANALYSIS_SELECTED = 'portfolioAnalysisFilters.selected';
export const PORTFOLIO_ANALYSIS_META = 'portfolioAnalysisFilters.meta';

export const EXPOSURE_BOXES_SETTINGS = 'exposureBoxesSettings';

export const BENCHMARKS_CONFIGURATION_FUND_SELECTED = 'benchmarksConfiguration.fund.selected';
export const BENCHMARKS_CONFIGURATION_HOLDINGS_SELECTED = 'benchmarksConfiguration.holdings.selected';

export const PERFORMANCE_ATTRIBUTION_FUND_SELECTORS_SELECTED = 'performanceAttributionSelectors.fund.selected';
export const PERFORMANCE_ATTRIBUTION_HOLDINGS_SELECTORS_SELECTED = 'performanceAttributionSelectors.holdings.selected';

export const AUDIENCES_CONFIG_USER_SESSION = 'audiencesConfig.userSession';
