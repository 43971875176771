import get from 'lodash/get';
import set from 'lodash/set';
import pick from 'lodash/pick';
import merge from 'lodash/merge';

import state from './state';
import { APP_CONFIG } from '../../config';

import mutations from './mutations';

const config = APP_CONFIG.store;

const listeners = {};
const keyName = `${config.storagePrefix}-state`;
const userKeyName = `${keyName}-user`;
const storage = window.sessionStorage;
const defaultUser = 'current';

export default class {
    user = defaultUser;
    storeKeyName;

    constructor() {
        const impersonator = window.impersonator;
        const currentUser = storage.getItem(userKeyName);
        if (!impersonator && currentUser !== defaultUser) {
            storage.removeItem(`${keyName}-${currentUser}`);
            storage.setItem(userKeyName, this.user);
        }
        else if (currentUser) {
            this.user = currentUser;
        } else {
            storage.setItem(userKeyName, this.user);
        }
        this.storeKeyName = `${keyName}-${this.user}`;
        const fromStorage = JSON.parse(storage.getItem(`${this.storeKeyName}`));
        this.state = merge(state, fromStorage);
    }

    changeStorage(user) {
        storage.setItem(userKeyName, user || defaultUser);
        if(!user) {
            storage.removeItem(this.storeKeyName);
        }
    }

    clearStorage() {
        storage.clear();
    }

    removeListener(path, listener) {
        if (!listeners[path]) {
            return;
        }
        const index = listeners[path].indexOf(listener);
        if (index) {
            listeners[path].splice(index, 1);
        }
    }
    get(path, listener) {
        listener(get(this.state, path));

        if (!listeners[path]) {
            listeners[path] = [];
        }

        listeners[path].push(listener);
    }

    /* get value from store */
    getValue(path) {
        return get(this.state, path);
    }

    set(path, payload, type) {
        const isPersistent = config.persistent.indexOf(path) !== -1;
        let newPayload = payload;

        if (mutations[path] && type) {
            newPayload = mutations[path].call(this, get(this.state, path), { type, payload });
        }

        set(this.state, path, newPayload);

        // console.groupCollapsed(
        //     `%c APP STATE --> %c${path}`,
        //     'background: green; color: black',
        //     'background: green; color: silver',
        // );
        // console.table(JSON.parse(JSON.stringify(state)));
        // console.groupEnd();

        if (!listeners[path]) {
            listeners[path] = [];
        }

        listeners[path].forEach((listener) => {
            listener(get(this.state, path));
        });

        if (isPersistent) {
            storage.setItem(this.storeKeyName, JSON.stringify(pick(this.state, config.persistent)));
        }
    }
}
